<template>
  <q-scroll-area class="fit bg-blue-grey-9 text-white">
    <q-list>
      <template v-for="menu in menuList" :key="menu">
        <template v-if="!menu.children">
          <q-item :to="menu.to" active-class="q-item-no-link-highlighting">
            <q-item-section avatar><q-icon :name="menu.icon" /></q-item-section>
            <q-item-section>
              <q-item-label>{{ menu.title }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <template v-else>
          <q-expansion-item :label="menu.title" :icon="menu.icon">
            <q-list class="q-pl-lg">
              <template v-for="submenu in menu.children" :key="submenu">
                <q-item :to="submenu.to">
                  <q-item-section avatar
                    ><q-icon :name="submenu.icon"
                  /></q-item-section>
                  <q-item-section>
                    <q-item-label>{{ submenu.title }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
          </q-expansion-item>
        </template>
      </template>
    </q-list>
  </q-scroll-area>
</template>
<script setup>
const menuList = [
  { title: "대시보드", to: "/", icon: "dashboard"},
  {
    title: "거래조회",
    icon: "payments",
    children: [
      {
        to: "/payments/card/list",
        use: true,
        title: "신용카드",
        icon: "credit_card",
      },
      {
        to: "/payments/bank/list",
        title: "지역화폐",
        icon: "menu_book",
        use: true,
      },
      {
        to: "/payments/cash/list",
        title: "현금영수증",
        icon: "local_atm",
        use: true,
      },
    ],
  },
  {
    title: "정산관리",
    icon: "currency_exchange",
    children: [
      {
        to: "/settlement/merchant/list",
        title: "정산내역",
        icon: "currency_exchange",
        use: true,
      },
      /*{
        to: "/settlement/merchant/list",
        title: "보류내역",
        icon: "currency_exchange",
        use: true,
      },*/
    ],
  },
  /*{
    title: "영업점관리",
    icon: "store",
    children: [
      {
        to: "/branch/list",
        title: "영업점 목록",
        icon: "store",
        use: true,
      },
      {
        to: "/branch/sales",
        title: "영업점 매출",
        icon: "shopping_bag",
        use: true,
      },
    ],
  },*/
  {
    title: "결제",
    icon: "payment",
    children: [
      {
        to: "/pay/card",
        title: "신용카드 수기결제",
        icon: "credit_score",
        use: true,
      },
      /*{
        to: "/pay/sms",
        title: "톡결제",
        icon: "sms",
        use: true,
      },
      {
        to: "/pay/sms/list",
        title: "톡결제 내역",
        icon: "speaker_notes",
        use: true,
      },*/
    ],
  },
  {
    title: "정보",
    icon: "info",
    children: [
      {
        to: "/vatreport",
        title: "부가세 자료조회",
        icon: "receipt_long",
        use: true,
      },
      {
        to: "/mypage",
        title: "마이페이지",
        icon: "person",
        use: true,
      },
      {
        to: "/notice/list",
        title: "공지사항",
        icon: "notifications",
        use: true,
      },
    ],
  },
];
</script>
